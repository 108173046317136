export const FASTCOMMENTS_TENANT_ID = 'i4UJvodPC'

export const MAX_FILE_SIZE = 50000000

export const COMPETITION_LIST_SECTIONS = {
  LIVE: 'live',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  PRACTICE: 'practice',
} as const

export const COMPETITION_LIST_SSR_PAGE_SIZE = 50

export const COMPETITION_LIST_CSR_PAGE_SIZE = 6

export const COMPETITION_LIST_ALL_PAGE_SIZE = 100

export const competitionDetailsPageDefaultMetaImageUrl =
  'https://fastly.codingal.com/images/competitions/meta-image.png'

export const HPE_CODEWARS_COMPETITION_SLUG = 'hpe-codewars-2021'

// New coding compeition slugs needs to be added here before going live.
// This for handling different UI depending on if the event is a competition or hackathon.
// TODO: Refactor this so that no hard coding is required for coding competitions frontend.
export const CODING_COMPETITION_SLUGS = [
  '57frwbrf_m',
  'hpe-codewars-2021-practice-arena',
  'hpe-codewars-2021',
  'codewars-2021-all-problems',
  'code-battle-1',
  'code-battle-practice-arena',
  'code-battle-2',
  'code-battle-3',
  'code-battle-4',
  'dps-codebigs',
  'codechamps',
  'dips-coding-jam-2021',
  'dips-coding-jam-21',
  'coding-challenge-practice-arena',
  'jaipuria-cypher-1',
  'cypher-final-round',
  'code-battle-5',
  'national-coding-olympiad-2021-code-battle',
  'algorithm-course-trial-class',
  'code-battle-6',
  'hpe-codewars-2022-code-battle',
  'codewars-all-practice-problems-2023',
  'ioi-algorithms-preparation-guide',
  'hpe-codewars-2023-code-battle',
  'coding-competition-dry-run',
  'codewars-all-practice-problems-2024',
  'hpe-codewars-2024-code-battle',
  'dryrunanbjwqbhfiejo1kwlakdswqkjrhio21uwpokql',
  'usaco-practice-battle-may24',
  'usaco-practice-battle-jun24',
  'usaco-practice-battle-jul24',
]

export const COMPETITION_TYPES = {
  HACKATHON: 'hackathon',
  CODING_CONTEST: 'codingcontest',
  QUIZ_CONTEST: 'quizcontest',
}

export const HPE_CODEWARS_2022_HACKATHON_SLUG = 'hpe-codewars-2022-hackathon'
export const HPE_CODEWARS_2023_HACKATHON_SLUG = 'hpe-codewars-2023-hackathon'
export const HPE_CODEWARS_2023_CODE_BATTLE_SLUG =
  'hpe-codewars-2023-code-battle'
export const COMPETITION_PAGE_UTM_PARAM = 'utm_source=comp-page'

export const SCHOOL_FIELD_INPUT_LIMITS = {
  MIN_LENGTH: 3,
  MAX_LENGTH: 255,
}

export const HPE_CODEWARS_2023_SLUGS = {
  CODE_BATTLE: 'hpe-codewars-2023-code-battle',
  HACKATHON: 'hpe-codewars-2023-hackathon',
  PRACTICE_PROBLEMS: 'codewars-all-practice-problems-2023',
}

export const HPE_CODEWARS_2024_SLUGS = {
  CODE_BATTLE: 'hpe-codewars-2024-code-battle',
  HACKATHON: 'hpe-codewars-2024-hackathon',
}

export const threeMemberTeamCompetitions = [
  HPE_CODEWARS_2022_HACKATHON_SLUG,
  HPE_CODEWARS_2023_HACKATHON_SLUG,
  HPE_CODEWARS_2024_SLUGS.HACKATHON,
  'hackyoung-olympiad-2024',
  'iit-kanpur-code-clash-championship',
]
