import Link from 'next/link'
import CompetitionCta from '@components/competitions/competition-cta'
import { CompetititonsListType } from '@lib/types/competitions'
import { useRouter } from 'next/router'
import { CompetitionTimer } from '@components/competitions/competition-timer'

import { useSessionContext } from '@contexts/session'
import { LazyAPIImage } from '@components/common/image'
import date, {
  SYSTEM_TIMEZONE,
  formatTimeByTimezone,
  TIME_FORMAT_TZ,
} from '@lib/utils/date.utils'
import RenderRichTextContent from '@components/common/render-rich-text-content'
import { Trophy } from '@components/common/icons/custom-icons'
import { threeMemberTeamCompetitions } from '@lib/constants/competitions'
import { t, Trans } from '@lingui/macro'

type Props = {
  competitionList: CompetititonsListType
  handleRegisterNow: (slug: string) => void
  cardClasses?: string
}

const CompetitionCards = ({
  competitionList,
  handleRegisterNow,
  cardClasses,
}: Props) => {
  const { timezone, loggedIn } = useSessionContext()
  const router = useRouter()

  const checkedTimezone = timezone ? timezone : SYSTEM_TIMEZONE

  return (
    <>
      {competitionList.map((data) => (
        <div
          className={`flex overflow-hidden py-2 pr-8 mx-5 my-4 bg-white rounded-md cursor-pointer md:mx-0 group ${cardClasses}`}
          key={data.title}
          onClick={() =>
            router.push({
              pathname: `/competitions/${data.slug}/`,
              query: router.query,
            })
          }
        >
          <div className="flex flex-wrap w-full my-2 ml-4 md:justify-start md:flex-no-wrap">
            <div style={{ width: '112px' }}>
              <LazyAPIImage
                className="object-contain dw-24 h-24 p-1 mx-2 mt-6 bg-white border rounded lg:m-2 text-grey-500 lg:self-center"
                src={data.organizer.logo}
                alt={t`Event Image`}
                height={96}
                width={96}
              />
            </div>
            <div className="flex flex-col justify-between mx-2 md:mr-4 md:text-left">
              <div className="mt-4">
                <Link
                  href={{
                    pathname: `/competitions/${data.slug}/`,
                    query: router.query,
                  }}
                >
                  <a className="text-xl md:text-xl font-600 text-grey hover:underline group-hover:text-orange">
                    {data.title}
                  </a>
                </Link>
                <p className="text-grey-800 font-500">
                  <Trans>
                    Hosted by:{' '}
                    <span className="font-500">{data.organizer.title}</span>
                  </Trans>
                </p>
              </div>
              <div className="flex flex-col flex-wrap py-2 md:flex-row">
                <div className="text-grey-800 font-600 md:mx-0">
                  {date(data.end).isBefore(date()) ? (
                    <Trans>
                      Ended at:
                      <span className="ml-2 text-grey-800 font-500">
                        {formatTimeByTimezone(
                          data.end,
                          checkedTimezone,
                          TIME_FORMAT_TZ
                        )}
                      </span>
                    </Trans>
                  ) : date(data.start).isBefore(date()) ? (
                    <Trans>
                      Ends in:
                      <CompetitionTimer endTime={data.end} />
                    </Trans>
                  ) : (
                    <Trans>
                      Starts at:
                      <span className="ml-2 text-grey-800 font-500">
                        {formatTimeByTimezone(
                          data.start,
                          checkedTimezone,
                          TIME_FORMAT_TZ
                        )}
                      </span>
                    </Trans>
                  )}
                </div>
                <div className="hidden w-0 mx-2 my-3 border border-grey-600 md:flex" />
                <div className="text-grey-800 font-600 md:mx-0">
                  <Trans>
                    Team size:
                    <span className="ml-2 text-grey-800 font-500">
                      {threeMemberTeamCompetitions.includes(data.slug)
                        ? t`Upto 3 members`
                        : t`1 member`}
                    </span>
                  </Trans>
                </div>
                <div className="hidden w-0 mx-2 my-3 border border-grey-600 md:flex" />
                {data.minGrade && data.maxGrade && (
                  <div className="text-grey-800 font-600 md:mx-0">
                    <Trans>
                      Grade:
                      <span className="ml-2 text-grey-800 font-500">
                        {data.minGrade.split(' ')[1]}-
                        {data.maxGrade.split(' ')[1]}
                      </span>
                    </Trans>
                  </div>
                )}
              </div>

              {data.prizeSummary && (
                <div className="flex items-start">
                  <div className="p-1 mr-1 bg-orange-200 rounded-full">
                    <Trophy className="w-4 text-orange-600" />
                  </div>
                  <RenderRichTextContent
                    className="max-w-screen-md text-grey-800"
                    content={data.prizeSummary}
                  />
                </div>
              )}
            </div>
            <div className="flex-none mt-4 mb-4 ml-2 md:ml-auto">
              <CompetitionCta
                loggedIn={loggedIn}
                isUpcoming={date(data.start).isAfter(date())}
                isOngoing={date().isBetween(data.start, data.end)}
                ended={date().isAfter(data.end)}
                isRegistered={data.isRegistered}
                handleRegisterNow={() => handleRegisterNow(data.slug)}
                isSubmissionDone={data.hasUserSubmitted}
                slug={data.slug}
                useShortMessage
              />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
export default CompetitionCards
