import { useEffect, useState } from 'react'
import date from '@lib/utils/date.utils'
import swal from 'sweetalert'
import Router from 'next/router'
import cogoToast from 'cogo-toast'

import { getCurrentTimeDifference } from '@lib/utils/competitions'
import classNames from 'classnames'

type SpanElementProps = JSX.IntrinsicElements['span']

type TimerProps = SpanElementProps & {
  endTime: string
  kick?: boolean
  slug?: string
}

/** span HTML element props are available */
export const CompetitionTimer = ({
  endTime,
  kick = false,
  slug,
  ...props
}: TimerProps) => {
  const [timeRemaining, setTimeRemaining] = useState(
    getCurrentTimeDifference(endTime)
  )
  const [showKickPrompt, setShowKickPrompt] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const difference = getCurrentTimeDifference(endTime)
      if (date().isSameOrAfter(endTime)) {
        clearInterval(interval)
        if (kick) setShowKickPrompt(true)
      } else {
        if (
          kick &&
          difference.days === 0 &&
          difference.hours === 0 &&
          difference.minutes === 15 &&
          difference.seconds === 0
        ) {
          const { hide } = cogoToast.info(
            'There are 15 minutes remaining before this competition ends!',
            {
              hideAfter: 30,
              onClick: () => hide(),
            }
          )
        }
        setTimeRemaining(difference)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!showKickPrompt) return

    swal({
      icon: 'warning',
      title: 'Hey!',
      text:
        'The maximum allotted time for this contest has elapsed. We hope you enjoyed participating in this content.You will now be taken to the competition page.',
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => Router.push(`/competitions/${slug}`))
  }, [showKickPrompt])

  return (
    <span
      {...props}
      className={classNames(props.className, {
        'text-grey-800 ml-2 font-400': !props.className,
      })}
    >
      <span className={timeRemaining?.days === 0 ? 'hidden' : ''}>
        {timeRemaining?.days} {timeRemaining?.days !== 1 ? 'days' : 'day'},{' '}
      </span>
      <span
        className={
          timeRemaining?.days === 0 && timeRemaining?.hours === 0
            ? 'hidden'
            : ''
        }
      >
        {timeRemaining?.hours} {timeRemaining?.hours !== 1 ? 'hrs' : 'hr'},{' '}
      </span>
      <span>
        {timeRemaining?.minutes} {timeRemaining?.minutes !== 1 ? 'mins' : 'min'}
      </span>
      <span
        className={
          timeRemaining?.days === 0 && timeRemaining?.hours === 0
            ? ''
            : 'hidden'
        }
      >
        , {timeRemaining?.seconds}{' '}
        {timeRemaining?.seconds !== 1 ? 'secs' : 'sec'}
      </span>
    </span>
  )
}
