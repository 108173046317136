import Router from 'next/router'
import Link from 'next/link'

import { CODING_COMPETITION_SLUGS } from '@lib/constants/competitions'
import Button from '@components/atoms/button'
import { t, Trans } from '@lingui/macro'

type Props = {
  loggedIn: boolean
  isUpcoming: boolean
  isOngoing: boolean
  ended: boolean
  isRegistered: boolean
  isSubmissionDone: boolean
  slug: string
  handleRegisterNow: () => void
  useShortMessage?: boolean
}

const CompetitionCta = ({
  loggedIn,
  isUpcoming,
  isOngoing,
  ended,
  isRegistered,
  handleRegisterNow,
  isSubmissionDone,
  slug,
  useShortMessage = false,
}: Props) => {
  const onClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    handleRegisterNow()
  }

  return (
    <>
      {!isRegistered && (isOngoing || isUpcoming) && (
        <Button
          onClick={(event) => onClickHandler(event)}
          intent={isOngoing ? 'success' : 'primary'}
          size="lg"
          className="h-auto my-2 lg:text-xl xl:text-2xl"
        >
          {isOngoing ? t`Register & Start` : t`Register now`}
        </Button>
      )}
      {ended && (
        <div>
          {useShortMessage && (
            <Button variant="outline" size="lg" className="h-auto my-2">
              <Trans>View competition</Trans>
            </Button>
          )}
          <p
            className={
              useShortMessage
                ? 'text-grey-800 text-sm text-center'
                : 'text-orange text-lg'
            }
          >
            <Trans>Competition has ended.</Trans>
          </p>

          {isSubmissionDone && loggedIn && !useShortMessage && (
            <p className="pt-2 leading-tight font-600 text-grey">
              <Trans>
                Thank you for submitting the project. <br />
                Please wait for the results to be announced.
              </Trans>
            </p>
          )}
          {!isSubmissionDone &&
            loggedIn &&
            !useShortMessage &&
            !CODING_COMPETITION_SLUGS.includes(slug) && (
              <p className="pt-2 leading-tight font-600 text-grey">
                <Trans>
                  You have not made any submissions. <br />
                  All the best for future competitions!
                </Trans>
              </p>
            )}
        </div>
      )}
      {loggedIn &&
        isRegistered &&
        isUpcoming &&
        (useShortMessage ? (
          <div className="flex flex-col items-end">
            <Button disabled size="lg" className="h-auto mx-auto my-2">
              <Trans>Register now</Trans>
            </Button>
            <p className="mx-auto text-sm text-grey-800">
              <Trans>You are registered.</Trans>
            </p>
          </div>
        ) : (
          <p className="font-600 text-grey lg:leading-tight">
            <Trans>
              You are registered. <br />
              Please wait for submission to start.
            </Trans>
          </p>
        ))}

      {loggedIn && isRegistered && isOngoing && (
        <>
          {!isSubmissionDone && !CODING_COMPETITION_SLUGS.includes(slug) && (
            <Button
              intent="success"
              size="lg"
              className="h-auto my-2"
              onClick={(event) => {
                event.stopPropagation()
                Router.push(`/competitions/${slug}/submit/`)
              }}
            >
              <Trans>Submit Project</Trans>
            </Button>
          )}

          {!isSubmissionDone && CODING_COMPETITION_SLUGS.includes(slug) && (
            <Button
              intent="success"
              size="lg"
              className="h-auto my-2 lg:text-xl xl:text-2xl"
              onClick={(event) => {
                event.stopPropagation()
                Router.push(`/competitions/${slug}/problems/`)
              }}
            >
              <Trans>Start now</Trans>
            </Button>
          )}

          {isSubmissionDone &&
            (useShortMessage ? (
              <div className="flex flex-col items-end">
                <Button
                  disabled
                  intent="success"
                  size="lg"
                  className="h-auto mx-auto my-2 opacity-50 hover:bg-green-200"
                >
                  <Trans>Submit Project</Trans>
                </Button>
                <p className="mx-auto text-sm text-grey-800">
                  <Trans>Submission accepted.</Trans>
                </p>
              </div>
            ) : (
              <div>
                <Link
                  as={`/competitions/${slug}/submit/`}
                  href="/competitions/[competitionName]/submit/"
                >
                  <a>
                    <Button intent="success" size="lg" className="h-auto my-2">
                      <Trans>Resubmit Project</Trans>
                    </Button>
                  </a>
                </Link>

                <p className="font-600 text-grey">
                  <Trans>Thank you for submitting the project. </Trans>
                  <br />
                  <Link
                    href="/competitions/[competitionName]/view-submission/"
                    as={`/competitions/${slug}/view-submission/`}
                  >
                    <a className="text-orange hover:underline font-600">
                      <Trans>View submission</Trans>
                    </a>
                  </Link>
                </p>
              </div>
            ))}
        </>
      )}
    </>
  )
}
export default CompetitionCta
