import { COMPETITION_LIST_SECTIONS } from '@lib/constants/competitions'
import {
  CompetitionListsType,
  CompetititonsListType,
} from '@lib/types/competitions'
import date from '@lib/utils/date.utils'

export const getDimensions = (element: HTMLElement) => {
  const { height } = element.getBoundingClientRect()
  const offsetTop = element.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}

export const getCurrentTimeDifference = (endTime: string) => {
  const differenceObj = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }
  const end = date(endTime)
  const now = date()

  if (end.isAfter(now)) {
    const difference = date.duration(date(endTime).diff(date()))

    const differenceInDays = end.diff(date(), 'days')

    differenceObj.days = differenceInDays

    differenceObj.hours = difference.hours()

    differenceObj.minutes = difference.minutes()

    differenceObj.seconds = difference.seconds()
  }

  return differenceObj
}

export const categorizeAndSortCompetitions = (
  competitions: CompetititonsListType
) => {
  const competitionsLists: CompetitionListsType = {
    upcoming: [],
    live: [],
    completed: [],
    practice: [],
  }

  competitions.forEach((competition) => {
    const { start, end } = competition
    if (date(start).isAfter(date()))
      competitionsLists.upcoming.push(competition)
    else if (date().isBetween(start, end) && !competition.isPractice)
      competitionsLists.live.push(competition)
    else if (date().isBetween(start, end) && competition.isPractice)
      competitionsLists.practice.push(competition)
    else competitionsLists.completed.push(competition)
  })

  competitionsLists.live.sort((competitionA, competitionB) =>
    date(competitionA.end).isBefore(date(competitionB.end)) ? -1 : 0
  )

  competitionsLists.practice.sort((competitionA, competitionB) =>
    date(competitionA.end).isBefore(date(competitionB.end)) ? -1 : 0
  )

  competitionsLists.upcoming.sort((competitionA, competitionB) =>
    date(competitionA.start).isBefore(date(competitionB.start)) ? -1 : 0
  )

  competitionsLists.completed.sort((competitionA, competitionB) =>
    date(competitionA.end).isAfter(date(competitionB.end)) ? -1 : 0
  )

  return competitionsLists
}

export const getDefaultActiveTab = (competitionLists: CompetitionListsType) => {
  let defualtActiveTab = ''
  if (competitionLists.live.length > 0)
    defualtActiveTab = COMPETITION_LIST_SECTIONS.LIVE
  else if (competitionLists.practice.length > 0)
    defualtActiveTab = COMPETITION_LIST_SECTIONS.PRACTICE
  else if (competitionLists.upcoming.length > 0)
    defualtActiveTab = COMPETITION_LIST_SECTIONS.UPCOMING
  else if (competitionLists.completed.length > 0)
    defualtActiveTab = COMPETITION_LIST_SECTIONS.COMPLETED

  return defualtActiveTab
}
