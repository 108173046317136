import DOMPurify from 'isomorphic-dompurify'
import { useEffect, useRef } from 'react'

type OptionsProps = {
  ADD_TAGS?: string[]
  ADD_ATTR?: string[]
}

interface RenderRichTextContentProps {
  className?: string
  content: string
  options?: OptionsProps
  willOpenLinksInNewTab?: boolean
}

export const RenderRichTextContent = ({
  className,
  content,
  options = {},
  willOpenLinksInNewTab = false,
}: RenderRichTextContentProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!willOpenLinksInNewTab) return
    ref.current
      .querySelectorAll('a')
      .forEach((anchorTag) => anchorTag.setAttribute('target', '_blank'))
  }, [willOpenLinksInNewTab])
  return (
    <div
      ref={ref}
      className={`rich-text-content ${className}`}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, options),
      }}
    />
  )
}

export default RenderRichTextContent
